.overlay {
  display: none;
  position: absolute;
  top: 0;
  background-color: rgba(175, 116, 6, 0.7);
  width: 100%;
  height: 100%;
}
.iconContainer {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: -100px;
}
.iconContainer p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  font-size: 60px;
  opacity: 0;
  transform: rotate(-90deg);
}

.phone {
  position: relative;
  left: 50%;
  margin-left: -50px;
  width: 0;
  height: 0;
  border: 0 solid #000;
  background: url("../../assets/images/pexels-stas-knop-3552948.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  box-sizing: border-box;
}
.phone i {
  text-align: center;
  width: 100%;
  line-height: 120px;
  font-size: 50px;
  color: #454545;
  opacity: 0;
}
@media only screen and (max-width: 1020px) {
  .overlay {
    display: block;
  }
  .overlay .phone {
    animation: sizeIncrease 0.5s forwards ease,
      borderIncrease 0.5s 0.5s forwards ease, rotateRight 0.7s 1s forwards ease;
  }
  .overlay .phone i {
    animation: fadeIn 0.5s 0.8s forwards ease;
  }
  .overlay .iconContainer p {
    animation: fadeIn 0.5s 0.5s forwards ease;
  }
}

@keyframes sizeIncrease {
  0% {
    width: 0;
    height: 10px;
  }
  50% {
    width: 100px;
    height: 10px;
  }
  100% {
    width: 100px;
    height: 160px;
  }
}

@keyframes borderIncrease {
  100% {
    border-width: 20px 10px;
  }
}

@keyframes fadeIn {
  100% {
    color: #000;
    opacity: 1;
  }
}

@keyframes rotateRight {
  100% {
    transform: rotate(90deg);
  }
}
