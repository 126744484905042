.Sequencer {
  height: 100vh;
  width: 100vw;
  background: #1c1c1e;
}

.seqContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.instructions {
  height: 60vh;
  width: 60vw;
  background: #1c1c1e;
  margin: 0 auto;
  z-index: 999;
  position: absolute;
  top: 20vh;
  left: 20vw;
  border: 2px solid white;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.5s ease-in-out;
  box-shadow: 10px 10px 10px 10px rgba(0.5, 0.5, 0.5, 0.5);
}

.instructions h1 {
  font-family: "Fuzzy Bubbles", cursive;
  color: black;
  font-size: 2rem;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  text-decoration: underline;
}

.instructions ul {
  font-family: "Fuzzy Bubbles", cursive;
  color: black;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  line-height: 2em;
}
